import React, { FunctionComponent, useState } from 'react';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Util from './shared/Util';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import firebase from 'firebase/app';
import fire from './fire';
import glogo from './images/btn_google_light_normal_ios.svg';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = (theme) => ({
  error: {
    color: 'red',
  },
  button: {
    marginTop: theme.spacing(2),
  },
});

const useStyles = makeStyles((theme) => ({
  error: {
    color: 'red',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const Register: FunctionComponent = () => {
  const classes = useStyles();
  const [ClubAffiliation, setClubAffiliation] = useState('');
  const [AffiliationErrorText, setAffiliationErrorText] = useState('');
  const [Phone, setPhone] = useState('');
  const [, setRegisterComplete] = useState(false);
  const [registerError, setRegisterError] = useState('');

  const onRegisterClicked = (/* event */) => {
    const error = !ClubAffiliation;
    setRegisterError('');
    if (error) {
      setAffiliationErrorText(error ? 'This field is required' : '');
    } else {
      setAffiliationErrorText('');
      Util.registerUser({ ClubAffiliation, Phone })
        .then(() => {
          // stop registering animation
          setRegisterComplete(true);
        })
        .catch((reason) => {
          // stop registering animation
          console.log('Error setting user: ' + reason);
          setRegisterError(reason instanceof Error ? reason.message : String(reason));
        });
    }
  };

  const handleLogin = (option) => {
    let provider = new firebase.auth.GoogleAuthProvider();
    if (option === 'facebook') {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    // let component = this;
    fire
      .auth()
      .signInWithPopup(provider)
      .then(function () {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;
        // console.log(user);
        // ...
      })
      .catch(function (error) {
        console.log('Sign in error: ' + JSON.stringify(error));
        // An error happened.
        if (error.code === 'auth/account-exists-with-different-credential') {
          // // Step 2.
          // // User's email already exists.
          // // The pending Google credential.
          // const pendingCred = error.credential;
          // // The provider account's email address.
          // const email = error.email;
          // // Get registered providers for this email.
          // firebase
          //   .auth()
          //   .fetchProvidersForEmail(email)
          //   .then(function (providers) {
          //     // Step 3.
          //     // If the user has several providers,
          //     // the first provider in the list will be the "recommended" provider to use.
          //     if (providers[0] === 'password') {
          //       // Asks the user his password.
          //       // In real scenario, you should handle this asynchronously.
          //       const password = firebase.auth().promptUserForPassword(); // TODO: implement promptUserForPassword.
          //       firebase
          //         .auth()
          //         .signInWithEmailAndPassword(email, password)
          //         .then(function (user) {
          //           // Step 4a.
          //           return user.linkWithCredential(pendingCred);
          //         })
          //         .then(function () {
          //           // Google account successfully linked to the existing Firebase user.
          //           // setState({ redirectToReferrer: true });
          //           //             firebase.auth().goToApp();
          //         });
          //       return;
          //     }
          //     // All the other cases are external providers.
          //     // Construct provider object for that provider.
          //     // TODO: implement getProviderForProviderId.
          //     //         var provider = firebase.auth().getProviderForProviderId(providers[0]);
          //     let provider = null;
          //     if (providers[0].indexOf('google') >= 0) {
          //       provider = new firebase.auth.GoogleAuthProvider();
          //     } else if (providers[0].indexOf('facebook') >= 0) {
          //       provider = new firebase.auth.FacebookAuthProvider();
          //     }
          //     // At this point, you should let the user know that he already has an account
          //     // but with a different provider, and let him validate the fact he wants to
          //     // sign in with this provider.
          //     // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
          //     // so in real scenario you should ask the user to click on a "continue" button
          //     // that will trigger the signInWithPopup.
          //     firebase
          //       .auth()
          //       .signInWithPopup(provider)
          //       .then(function (result) {
          //         // Remember that the user may have signed in with an account that has a different email
          //         // address than the first one. This can happen as Firebase doesn't control the provider's
          //         // sign in flow and the user is free to login using whichever account he owns.
          //         // Step 4b.
          //         // Link to Google credential.
          //         // As we have access to the pending credential, we can directly call the link method.
          //         result.user
          //           .linkWithCredential(pendingCred)
          //           .then(function () {
          //             // Google account successfully linked to the existing Firebase user.
          //             //             firebase.auth().goToApp();
          //             // setState({ redirectToReferrer: true });
          //           })
          //           .catch((reason) => {
          //             console.log('Account linking failed:', reason);
          //           });
          //       });
          //   });
        }
      });
  };

  // See example at https://reacttraining.com/react-router/web/example/auth-workflow

  const contentStyle = { margin: '0 16px' };
  const stepIndex = Util.isUserRegistered() ? 2 : Util.isUserSignedIn() ? 1 : 0;
  return (
    <section>
      <div style={{ width: '100%', maxWidth: 700, margin: 'auto', marginTop: 16 }}>
        <Stepper activeStep={stepIndex}>
          <Step>
            <StepLabel>Login with Google account</StepLabel>
          </Step>
          <Step>
            <StepLabel>Provide Registration Data</StepLabel>
          </Step>
          <Step>
            <StepLabel>Registration Complete</StepLabel>
          </Step>
        </Stepper>
        <div style={contentStyle}>
          {registerError && <div style={{ color: '#f00' }}>${registerError}</div>}
          {stepIndex === 0 ? (
            <div>
              <p>
                CrewTimer uses google authentication to protect your account. Please click on the Google icon below to
                begin your registration process. By clicking on the Google icon you agree to the{' '}
                <a href="/termsofuse">CrewTimer Terms of Use</a>.
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <IconButton onClick={() => handleLogin('google')} size="large">
                        <img src={glogo} alt="Login with Google" />
                      </IconButton>
                    </td>
                    <td>
                      <div style={{ marginLeft: '10px' }} onClick={() => handleLogin('google')}>
                        Login with Google
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : stepIndex === 1 ? (
            <div>
              <p>Please provide a bit of information about your planned use of CrewTimer</p>
              {AffiliationErrorText && (
                <Typography className={classes.error} variant="subtitle1">
                  {AffiliationErrorText}
                </Typography>
              )}
              <TextField
                variant="standard"
                name="ClubAffiliation"
                label="Rowing Club Affiliation"
                error={Boolean(AffiliationErrorText)}
                onChange={(event) => setClubAffiliation(event.target.value)}
              />
              <br />
              <TextField
                variant="standard"
                name="Phone"
                label="Contact Phone Number"
                onChange={(event) => setPhone(event.target.value)}
              />

              <br />
              <Button className={classes.button} variant="contained" onClick={onRegisterClicked}>
                Register
              </Button>
            </div>
          ) : (
            <p>
              Welcome! You have successfully registered with CrewTimer. You can now begin setting up your regatta
              configuration by clicking on the CrewTimer logo at the top of the page or visiting{' '}
              <a href="https://admin.crewtimer.com">https://admin.crewtimer.com</a>.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};
export default withStyles(styles)(Register);
