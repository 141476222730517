import React, { FunctionComponent } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Util from './shared/Util';
import firebase from 'firebase/app';
import fire from './fire';
import glogo from './images/btn_google_light_normal_ios.svg';
import logo from './logo.svg';
import ActionStore from '@mui/icons-material/ShoppingCart';

const Login: FunctionComponent<{ redirectToReferrer: boolean }> = ({ redirectToReferrer }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = (option) => {
    let provider: firebase.auth.AuthProvider | undefined;
    if (option === 'google') {
      provider = new firebase.auth.GoogleAuthProvider();
    } else if (option === 'facebook') {
      provider = new firebase.auth.FacebookAuthProvider();
    } else {
      provider = new firebase.auth.GoogleAuthProvider();
    }

    fire
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;
        // ...
      })
      .catch((error) => {
        console.log('Sign in error: ' + JSON.stringify(error));
        // An error happened.
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Step 2.
          // User's email already exists.
          // The pending Google credential.
          // const pendingCred = error.credential;
          // The provider account's email address.
          // const email = error.email;
          // Get registered providers for this email.
          // firebase.auth().fetchProvidersForEmail(email).then( (providers) =>{
          // Step 3.
          // If the user has several providers,
          // the first provider in the list will be the "recommended" provider to use.
          // if (providers[0] === 'password') {
          //   // Asks the user his password.
          //   // In real scenario, you should handle this asynchronously.
          //   const password = firebase.auth().promptUserForPassword(); // TODO: implement promptUserForPassword.
          //   firebase.auth().signInWithEmailAndPassword(email, password).then(function (user) {
          //     // Step 4a.
          //     return user.linkWithCredential(pendingCred);
          //   }).then( () => {
          //     // Google account successfully linked to the existing Firebase user.
          //     this.setState({ redirectToReferrer: true })
          //     //             firebase.auth().goToApp();
          //   });
          //   return;
          // }
          // All the other cases are external providers.
          // Construct provider object for that provider.
          // TODO: implement getProviderForProviderId.
          //         var provider = firebase.auth().getProviderForProviderId(providers[0]);
          //   let provider = null;
          //   if (providers[0].indexOf('google') >= 0) {
          //     provider = new firebase.auth.GoogleAuthProvider();
          //   } else if (providers[0].indexOf('facebook') >= 0) {
          //     provider = new firebase.auth.FacebookAuthProvider();
          //   }
          //   // At this point, you should let the user know that he already has an account
          //   // but with a different provider, and let him validate the fact he wants to
          //   // sign in with this provider.
          //   // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
          //   // so in real scenario you should ask the user to click on a "continue" button
          //   // that will trigger the signInWithPopup.
          //   firebase.auth().signInWithPopup(provider).then(function (result) {
          //     // Remember that the user may have signed in with an account that has a different email
          //     // address than the first one. This can happen as Firebase doesn't control the provider's
          //     // sign in flow and the user is free to login using whichever account he owns.
          //     // Step 4b.
          //     // Link to Google credential.
          //     // As we have access to the pending credential, we can directly call the link method.
          //     result.user.linkWithCredential(pendingCred).then(function () {
          //       // Google account successfully linked to the existing Firebase user.
          //       //             firebase.auth().goToApp();
          //       this.setState({ redirectToReferrer: true })
          //     }).catch((reason) => { console.log("Account linking failed:", reason) });
          //   });
          // });
        }
      });
  };

  // https://v5.reactrouter.com/web/example/auth-workflow
  // For router v6, See https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
  const from = (location as any).state?.from?.pathname || '/';

  return (
    <section>
      <div style={{ width: '100%', maxWidth: 700, margin: 'auto' }}>
        {redirectToReferrer && Util.isUserRegistered() ? (
          <Navigate to={from} replace />
        ) : Util.isUserSignedIn() ? (
          <Navigate to={'/register'} replace />
        ) : null}
        {from && <p>You must log in or Register</p>}
        <table>
          <tbody>
            <tr>
              <td>
                <IconButton
                  // label="Login with Google"
                  // tooltip="Login with Google"
                  onClick={() => handleLogin('google')}
                  size="large"
                >
                  <img src={glogo} alt="Login with Google" />
                </IconButton>
              </td>
              <td>
                <div style={{ marginLeft: '10px' }} onClick={() => handleLogin('google')}>
                  Login with Google
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <IconButton
                  // label="Register with CrewTimer"
                  // tooltip="Register with CrewTimer"
                  onClick={() => navigate('/register', { replace: true })}
                  size="large"
                >
                  <img height="40px" width="40px" src={logo} alt="Register with CrewTimer" />
                </IconButton>
              </td>
              <td>
                <div style={{ marginLeft: '10px' }} onClick={() => navigate('/register', { replace: true })}>
                  Register with CrewTimer
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <IconButton
                  // label="CrewTimerStore"
                  // tooltip="Visit the CrewTimer store"
                  onClick={() => navigate('/store', { replace: true })}
                  size="large"
                >
                  <ActionStore />
                </IconButton>
              </td>
              <td>
                <div style={{ marginLeft: '10px' }} onClick={() => navigate('/store', { replace: true })}>
                  Visit the CrewTimer store.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Login;
